<template>
    <div class="pageContainer">
        <div class="board">
            <div class="tContainer">
                <div>上链接入企业</div>
                <div>Uplink Access Enterprise</div>
            </div>
            <img src="../assets/image/img1.png">
        </div>
        <div class="page">
            <div class="ul">
                <div class="item" v-for="(item,index) in list" :key="index">{{item.name}}</div>
            </div>
            <div class="cooperativePartner">
                <div class="t">合作伙伴</div>
                <div class="PartnerContainer">
                    <div class="imgContainer">
                        <img src="../assets/image/partner1.png" alt="">
                    </div>
                    <div class="imgContainer">
                        <img src="../assets/image/partner2.png" alt="">
                        <div class="name">纯白矩阵</div>
                    </div>
                    <div class="imgContainer">
                        <img src="../assets/image/partner3.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        components: {},
        data() {
            return {
                list: [
                    { name: '宁夏盐池滩羊集团' },
                    { name: '南京农业大学信息科技学院' },
                    { name: '江苏省江阴市华西村' },
                    { name: '湖北永信食品有限公司' },
                    { name: '江苏一号农场科技股份有限公司' },
                    { name: '江苏省农科院六合动物实验基地' },
                    { name: '江苏省南京市溧水区傅家边科技园' },
                    { name: '江苏省农业科学院蔬菜所瓜类作物研发室' },
                    { name: '浙江省建德市俊伟草莓采摘基地' },
                    { name: '江苏省农业科学院果树研究所' },
                    { name: '上海联中食用菌专业合作社' },
                    { name: '南京市溧水区江苏省农业科学院溧水基地' },
                    { name: '四川省广汉市锦花粮食种植专业合作社' },
                    { name: '溧阳市天目湖绿萃特色农产品专业合作社' },
                    { name: '黑龙江金玛农业有限公司' },
                    { name: '涟水县安东米富种植专业合作联社' },
                    { name: '天津云生农业种植合作社（新鹏蔬菜）' },
                    { name: '台州市黄岩区北坪家庭农场' },
                    { name: '天津市滨海新区汉沽区永丰农场' }
                ]
            }
        },
        methods: {
            back() {}
        }
    }
</script>

<style scoped lang="less">
    .pageContainer {
        height: calc(100vh - 128px);
        width: 100%;
    }

    .page {
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 64px;
        padding-bottom: 54px;


        .ul {
            display: flex;
            justify-content: space-between;
            width: 700px;
            flex-wrap: wrap;
            font-size: 16px;
            font-weight: 400;
            color: #333333;

            .item {
                line-height: 36px;
                width: 300px;
            }
        }

        .cooperativePartner {
            margin-top: 64px;

            .t {
                font-size: 28px;
                font-weight: 400;
                color: #333333;
                text-align: center;
            }

            .PartnerContainer {
                margin-top: 30px;
                display: flex;

                .imgContainer {
                    margin-left: 50px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 180px;
                    height: 100px;
                    background: #FFFFFF;
                    box-shadow: inset 0px 3px 8px 1px rgba(7, 50, 43, 0.1000);
                    border-radius: 10px 10px 10px 10px;
                    opacity: 1;
                    border: 1px solid rgba(82, 116, 187, 0.1000);

                    &:first-of-type {
                        margin-left: 0;
                    }

                    .name {
                        font-size: 12px;
                        font-weight: 400;
                        color: #666666;
                        margin-top: 7px;
                    }
                }
            }
        }
    }

    .pageContainer {
        width: 100%;
        background-color: rgba(255, 255, 255, 1);
        position: relative;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgba(244, 245, 244, 1);
            height: 300px;
            width: 100%;
            z-index: 0;
        }
    }

    .board {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 300px;
        width: 1200px;
        margin: 0 auto;
        padding-left: 100px;

        .tContainer {
            div {
                &:nth-child(1) {
                    font-size: 48px;
                    font-weight: 400;
                    color: #333333;
                }

                &:nth-child(2) {
                    font-size: 20px;
                    font-weight: 300;
                    color: #999999;
                    margin-top: 8px;
                }
            }

        }

        img {
            margin-left: 176px;
            width: 600px;
        }
    }
</style>